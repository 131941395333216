<script setup>
import {ref} from "vue"
import FileUpload from "primevue/fileupload"

const emit = defineEmits(['uploaded'])

defineProps({
    chooseLabel: {
        type: String,
        default: "Choisir une image",
    }
})
const fileUpload = ref()

const uploadHandler = async (event)=>{
  emit('uploaded', event.files[0])
}

</script>
<template>
    <FileUpload
        ref="fileUpload"
        icon="mdi mdi-upload"
        mode="basic"
        name="file[]"
        :chooseLabel="chooseLabel"
        accept="image/png, image/jpeg"
        :maxFileSize="2000000"
        auto
        withCredentials
        custom-upload
        @uploader="uploadHandler"
    />
</template>

<script setup>
import {ref, onMounted, computed} from "vue"

import InputText from "primevue/inputtext"
import Button from "primevue/button"
import Dialog from 'primevue/dialog'

import OrganizationRidet from '@/components/Organization/Ridet.vue'
import RidetFormFreelancer from '@/components/RidetFormFreelancer.vue'
import {useOrganizations} from "@/stores/organizations"
import Skeleton from "primevue/skeleton"
import Message from "primevue/message"
import NoRecords from "@/components/NoRecords.vue";

const organizationStore = useOrganizations()

const emit = defineEmits(['selected', 'hide'])

const props = defineProps({
    disabled: {
        type: [Boolean],
        default: false,
    },
    label: {
        type: [String],
        default: 'Sélectionnez votre organisme',
    },
    isSelf: {
        type: Boolean,
        default: true,
    },
    autofocus: Boolean,
})

const ridet = defineModel('ridet')
const ridetNumber = defineModel('ridetNumber')

const autofocus = ref(!!props.autofocus)

const picking = ref(false)
const records = ref()
const q = ref()

const searchInput = ref()

const loading = ref(false);
const querying = ref(false);

const loadingError = ref()
const queryingError = ref()

const isFreeLancer = ref(false)

const freeLancerForm = computed(() => {
    if (ridet.value && ridet.value.isFreeLancer) {
        return ridet.value.fields
    } else if (isRidetNumber.value) {
        const matched = q.value.match(/^([0-9]{7})\.([0-9]{3})$/)
        return {
            rid7: matched[1],
            ndegetablissement: matched[2],
        }
    }
    return {}
})

const pickerClasses = computed(() => {
    return {
        'ridet-item': true,
        'p-3': true,
        'cursor-pointer': !isDisabled.value,
        'data-list-item': true,
        'pickable': true,
    }
})

const isDisabled = computed(() => {
    if (props.disabled) return true
    return false
})

const setPicking = (state = true) => {

    picking.value = state && !isDisabled.value

    if (ridet.value && ridet.value.isFreeLancer) {
        enableFreelancer()
    } else if (picking.value && ridetNumber.value) {
        q.value = ridetNumber.value
        query()
    }

    return picking.value
}

let timeout = null;
const keyup = () => {
    if (q.value && q.value.length < 3) return;

    query()
}

const query = () => {
    querying.value = true
    records.value = []
    if (timeout) {
        clearTimeout(timeout)
    }

    timeout = setTimeout(async () => {
        try {
            const result = await organizationStore.queryRidet(q.value)
            records.value = result.records
        } catch (e) {
            queryingError.value = e.message
        }
        querying.value = false
    }, 500)
}

const isRidetNumber = computed(() => {
    return q.value && q.value.match(/^[0-9]{6,7}\.[0-9]{3}$/g)
})

const freeLancerSentence = computed(() => {
    if (props.isSelf) {
        return "Si vous êtes travailleur indépendant (patenté) en nom propre."
    }
    return "Si c'est un travailleur indépendant (patenté) en nom propre."
})


const freeLancerSentenceNoResult = computed(() => {
    if (props.isSelf) {
        return "Si vous êtes travailleur indépendant (patenté) en nom propre, c'est normal."
    }
    return "Si c'est un travailleur indépendant (patenté) en nom propre, c'est normal."
})


const freeLancerButtonLabel = computed(() => {
    if (props.isSelf) {
        return 'Je suis travailleur indépendant'
    }
    return "C'est un travailleur indépendant"
})

const dialogShow = () => {
    //searchInput.value.$el.focus()
}
const itemClasses = (item) => {
    let classes = 'item p-3 cursor-pointer'
    if (ridet.value && (`${ridet.value.fields.rid7}.${ridet.value.fields.ndegetablissement}` === `${item.fields.rid7}.${item.fields.ndegetablissement}`)) {
        classes += ' selected'
    }
    return classes
}
const select = (record) => {
    ridet.value = record
    ridetNumber.value = `${record.fields.rid7}.${record.fields.ndegetablissement}`
    emit('selected', record)
    picking.value = false
    //$reset()
}
/*const $reset = () => {
    records.value = {}
    q.value = ''
    picking.value = false
}*/

const toggleFreelancer = (value) => {
    if (undefined !== value) {
        if (value) enableFreelancer()
        else disableFreelancer()
    } else {
        if (isFreeLancer.value) disableFreelancer()
        else enableFreelancer()
    }
}

const enableFreelancer = () => {
    isFreeLancer.value = true
}
const disableFreelancer = () => {
    if (freeLancerForm.value && freeLancerForm.value.rid7) {
        q.value = `${freeLancerForm.value.rid7}.${freeLancerForm.value.ndegetablissement}`
        query()
    }

    isFreeLancer.value = false
    records.value = undefined
}

const FreeLancerUpdated = (data) => {
    select({fields: data, isFreeLancer: true})
}

onMounted(async () => {
    if (!ridet.value && ridetNumber.value) {
        loading.value = true
        try {
            const result = await organizationStore.queryRidet(ridetNumber.value)
            if (result && result.records.length) {
                select(result.records[0])
            } else {
                //loadingError.value = `Le numéro de RIDET ${selected.value} ne correspond a aucun établissement actif au RIDET.`
            }
        } catch (e) {
            loadingError.value = e.message
        }
        loading.value = false
    }
})
</script>
<template>
    <Message v-if="loadingError" severity="error" :closable="false">{{ loadingError }}</Message>
    <div v-if="ridetNumber || ridet" :class="pickerClasses" @click="()=> {if(!isDisabled) setPicking()}">
        <div v-if="loading" class="mt-4 text-center">
            <i class="mdi mdi-spin mdi-loading text-2xl"/>
        </div>
        <div class="flex align-items-center" v-else>
            <OrganizationRidet :ridet="ridet" class="w-full" v-if="ridet"/>
            <div class="w-full" v-else>
                Aucune établissement actif pour le numéro de RIDET {{ ridetNumber }}.
            </div>
            <div class="flex-grow-0" v-if="!isDisabled">
                <i class="mdi mdi-reload" @click="(e)=>setPicking()"></i>
            </div>
        </div>
    </div>
    <div v-else class="text-center">
        <Button :label="label" icon="mdi mdi-button-cursor" @click="(e)=>setPicking()"
                :autofocus="autofocus"/>
    </div>
    <Dialog v-model:visible="picking" modal header="Sélectionner un organisme"
            :style="{ width: '50vw' }"
            :draggable="false"
            :breakpoints="{ '960px': '75vw', '641px': '95vw' }"
            @show="dialogShow"
            @hide="(e)=>emit('hide', e)"
    >

        <div v-if="! isFreeLancer">
            <p class="p-inputgroup">
            <span class="p-float-label p-input-icon-right">
                <i :class="querying ? 'mdi mdi-loading mdi-spin' : 'mdi mdi-magnify'"/>
                <input-text @keyup="keyup" v-model="q" ref="searchInput" autofocus/>
                <label for="ac">Rechercher</label>
            </span>
            </p>
            <p>
                Recherchez par numéro de RIDET (ex: 1234567.001) ou par nom d'organisme.
            </p>
            <p v-if="!q">
                <Message class="p-message-large" :closable="false"><p class="mt-0">{{
                        freeLancerSentence
                    }} Cliquez ci-dessous: </p>
                    <Button @click="toggleFreelancer">{{ freeLancerButtonLabel }}</Button>
                </Message>
            </p>
            <div v-if="querying" class="mt-4">
                <Skeleton width="100%" height="4rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="4rem" class="mb-2"></Skeleton>
                <Skeleton width="100%" height="4rem" class="mb-2"></Skeleton>
            </div>
            <div v-if="records && records.length" class="flex flex-column gap-4 data-list">
                <div class="flex flex-column gap-4">
                    <div @click="(event)=>select(record, event)" v-for="record in records" :key="record"
                         :class="itemClasses(record)">
                        <OrganizationRidet :ridet="record"/>
                    </div>
                </div>
            </div>
            <NoRecords v-else-if="q !== undefined && records !== undefined && q.length >= 3 && !loading && !querying">
                <div v-if="!isRidetNumber">
                    <p>Aucun établissement actif n'à été trouvé pour cette recherche</p>
                </div>
                <div v-else-if="!loading">
                    <p class="align-items-center">Aucun établissement actif n'à été trouvé pour ce numéro de Ridet.</p>

                    <Message class="p-message-large" :closable="false"><p class="mt-0">{{ freeLancerSentenceNoResult }}
                        Cliquez ci-dessous: </p>
                        <Button @click="toggleFreelancer">{{ freeLancerButtonLabel }}</Button>
                    </Message>

                </div>
            </NoRecords>
        </div>
        <div v-else>
            <RidetFormFreelancer v-bind:data="freeLancerForm" @done="FreeLancerUpdated" @cancel="disableFreelancer"/>
        </div>
    </Dialog>

</template>
